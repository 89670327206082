<template>
  <div class="app-notice-container">
    <div>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-radio-group @change="handleAppChange" v-model="app_type" style="margin-bottom: 10px;">
            <el-radio-button v-for="app in appList" :key="app.type" :label="app.type">
              {{ app.name }}
            </el-radio-button>
          </el-radio-group>
        </el-col>
        <el-col :span="12" :offset="0">
          <div style="text-align: right" class="create-option">
            <el-button type="primary" @click="showCreateDialog = true">
              添加公告
            </el-button>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-table :data="appnoticeList.items" border stripe>
      <el-table-column
        v-for="col in columns"
        :prop="col.id"
        :key="col.id"
        :label="col.label"
        :width="col.width"
        :formatter="col.formatter"
      ></el-table-column>
      <el-table-column label="操作" width="80" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.row)">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="padding: 20px 0; text-align: right;">
      <el-pagination
        :current-page="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="appnoticeList.total"
        background
        @size-change="sizeChange"
        @current-change="currentChange"
      ></el-pagination>
    </div>

    <el-dialog
      :title="isEdit ? '编辑公告' : '添加公告'"
      :visible.sync="showCreateDialog"
      width="600px"
      @close="onClose"
    >
      <el-form ref="form" :model="postForm" label-width="80px">
        <el-form-item label="公告标题" required>
          <el-input v-model="postForm.title"></el-input>
        </el-form-item>
        <el-form-item label="公告内容" required>
          <el-input v-model="postForm.detail" type="textarea" :rows="8"></el-input>
        </el-form-item>
        <el-form-item label="是否开启" required>
          <el-switch
            v-model="postForm.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="-1"
          ></el-switch>
        </el-form-item>
        <el-form-item label="倒计时">
          <el-input v-model="postForm.display_time">
            <template slot="append">秒</template>
          </el-input>
        </el-form-item>
        <el-form-item v-if="!isEdit" label="公告主题" required>
          <el-input v-model="postForm.subject" placeholder="可与标题一致"></el-input>
        </el-form-item>
        <el-form-item v-if="!isEdit" label="语言" required>
          <el-radio-group v-model="postForm.language">
            <el-radio-button label="zh-cn">中文</el-radio-button>
            <el-radio-button label="en">英文</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="!isEdit" label="应用APP" required>
          <el-radio-group v-model="postForm.app_type">
            <el-radio-button v-for="app in appList" :key="app.type" :label="app.type">
              {{ app.name }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="showCreateDialog = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { editAppNotice, postAppNotice } from "@/api/appnotice";
import dayjs from "dayjs";
import { mapGetters } from "vuex";

const LANG_MAP = {
  "zh-cn": "中文",
  en: "英文",
};

export default {
  data() {
    return {
      columns: [
        { id: "id", label: "ID", width: 80 },
        { id: "subject", label: "主题" },
        { id: "title", label: "公告标题" },
        { id: "detail", label: "公告内容", width: 240 },
        {
          id: "status",
          label: "状态",
          width: 80,
          formatter: (row) => {
            switch (+row.status) {
              case 1:
                return <el-tag type="success">开启</el-tag>;
              case -1:
                return <el-tag type="warning">关闭</el-tag>;
              default:
                return row.status;
            }
          },
        },
        {
          id: "language",
          label: "语言",
          formatter: (row) => {
            return LANG_MAP[row.language];
          },
        },
        {
          id: "created_at",
          label: "创建时间",
          formatter: (row) => dayjs(row.created_at * 1000).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          id: "updated_at",
          label: "更新时间",
          formatter: (row) => dayjs(row.updated_at * 1000).format("YYYY-MM-DD HH:mm:ss"),
        },
        { id: "display_time", label: "倒计时", width: 100 },
      ],
      postForm: {
        status: -1,
        title: "",
        detail: "",
        subject: "",
        language: "zh-cn",
        app_type: 1,
      },
      isEdit: false,
      showCreateDialog: false,
      currentPage: 1,
      pageSize: 10,
      app_type: 1,
      appList: [
        {
          type: 1,
          name: "钱包Pro",
        },
        {
          type: 5,
          name: "恒境",
        },
        {
          type: 6,
          name: "星界",
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapGetters(["appnoticeList"]),
  },
  methods: {
    fetchData() {
      let query = {};
      query.page = this.currentPage;
      query.page_size = this.pageSize;
      query.app_type = this.app_type;
      this.$store.dispatch("appnotice/getAppnoticeList", query);
    },
    handleAppChange() {
      this.pageSize = 30;
      this.currentPage = 1;
      this.fetchData();
    },
    sizeChange(size) {
      this.pageSize = size;
      this.fetchData();
    },
    currentChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    handleEdit(data) {
      this.showCreateDialog = true;
      this.isEdit = true;
      this.postForm = {
        id: data.id,
        status: data.status,
        title: data.title,
        detail: data.detail,
        app_type: data.app_type,
        display_time: +data.display_time,
      };
    },

    onClose() {
      this.postForm = {
        status: -1,
        title: "",
        detail: "",
        subject: "",
        language: "zh-cn",
        app_type: 1,
      };
      this.showCreateDialog = false;
      this.isEdit = false;
    },
    onSubmit() {
      if (Object.values(this.postForm).includes("")) {
        return this.$message({
          message: "请填写必填项！",
          duration: 3000,
          type: "error",
        });
      }

      const isPositiveInt = /^[0-9]\d*$/;
      if (!isPositiveInt.test(this.postForm.display_time)) {
        this.$message.error("倒计时为0或正整数");
        return;
      }

      if (this.isEdit) {
        const params = { ...this.postForm, display_time: +this.postForm.display_time };
        editAppNotice(params).then((data) => {
          if (+data.data.code) {
            this.$message({
              message: data.data.msg,
              duration: 3000,
              type: "error",
            });
            this.handleSuccess();
          } else {
            this.$message({
              message: data.data.msg || "操作成功",
              duration: 3000,
              type: "success",
            });
            this.handleSuccess();
          }
        });
      } else {
        const params = { ...this.postForm, display_time: +this.postForm.display_time };
        postAppNotice(params).then((data) => {
          if (+data.data.code) {
            this.$message({
              message: data.data.msg,
              duration: 3000,
              type: "error",
            });
            this.handleSuccess();
          } else {
            this.$message({
              message: data.data.msg,
              duration: 3000,
              type: "success",
            });
            this.handleSuccess();
          }
        });
      }
    },
    handleSuccess() {
      this.showCreateDialog = false;
      this.postForm = {};
      this.isEdit = false;
      this.fetchData();
    },
  },
};
</script>

<style lang="less" scoped>
.app-notice-container {
  margin: 20px;

  .header {
    margin-bottom: 20px;
    text-align: right;
    margin-right: 20px;
    font-size: 14px;

    > .label {
      margin-right: 12px;
    }
  }
}
</style>
